import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MainLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`A family firm`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Since 1930, the Monticelli family produces high quality `}<a parentName="p" {...{
            "href": "/en/products"
          }}>{`wood veneers`}</a>{`. This long experience, coupled with a genuine passion for wood and all its applications, enables Eredi Monticelli today to continue this long tradition and to offer products and tailor made services to satisfy the different requirements of our customers.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The latest news is represented by our `}<a parentName="p" {...{
            "href": "/en/country-furnitures"
          }}>{`country style furniture`}</a>{` for houses and gardens produced with our special care for details. All our country furniture are made with cedar wood. Cedar allows us to produce single board tables (up to 4.40 metres long), to enrich your houses and gardens with a touch of typical Italian handicraft.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Quality, flexibility and attention to details, combined with our availability to relate to our customers, make Eredi Monticelli the natural choice for the exigent buyer.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      